import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import zh_CN from 'element-plus/dist/locale/zh-cn.mjs' // 中文
import 'element-plus/dist/index.css'
import './assets/public.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'  // 导入所有图标

// 添加 ResizeObserver 错误处理
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};

const app = createApp(App)

// 注册所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 配置全局的 ElMessage 默认选项
app.use(ElementPlus, {
  config: {
    // 所有消息提示偏移量为80px
    message: {
      offset: 80
    }
  }
})

app.use(router)
app.mount('#app')
