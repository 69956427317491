import { createRouter, createWebHistory } from "vue-router";

const pcRoutes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/pc/auth/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/pc/auth/Register.vue"),
  },
  {
    path: "/reset",
    name: "Reset",
    component: () => import("../views/pc/auth/ResetPassword.vue"),
  },
  {
    path: "/home",
    name: "Layout",
    component: () => import("../layout/Layout.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../views/pc/Index.vue"),
        meta: { title: "首页" },
      },
      {
        path: "system/menu",
        name: "MenuManage",
        component: () => import("../views/pc/system/MenuManage.vue"),
        meta: { title: "菜单管理" },
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("../views/pc/user/Profile.vue"),
        meta: { title: "个人信息" },
      },
      {
        path: "password",
        name: "Password",
        component: () => import("../views/pc/user/Password.vue"),
        meta: { title: "修改密码" },
      },
      {
        path: "system/invitation",
        name: "InvitationManage",
        component: () => import("../views/pc/invite/InvitationManage.vue"),
        meta: { title: "邀请管理" },
      },
      {
        path: "system/user",
        name: "UserManage",
        component: () => import("../views/pc/system/UserManage.vue"),
        meta: { title: "用户管理" },
      },
      {
        path: "system/role",
        name: "RoleManage",
        component: () => import("../views/pc/system/RoleManage.vue"),
        meta: { title: "角色管理" },
      },
      {
        path: "system/email",
        name: "EmailManage",
        component: () => import("../views/pc/email/EmailManage.vue"),
        meta: { title: "邮件管理" },
      },
    ],
  }
];

export default pcRoutes; 