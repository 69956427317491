import { createRouter, createWebHistory } from "vue-router";
import pcRoutes from "./pc";
import mobileRoutes from "./mobile";

// 检测设备类型
const isMobile = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = [
    "mobile",
    "android",
    "iphone",
    "ipod",
    "ipad",
    "windows phone",
  ];
  return mobileKeywords.some((keyword) => userAgent.includes(keyword));
};

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes: isMobile() ? mobileRoutes : pcRoutes,
});

// 路由守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("user_token");
  const isMobileDevice = isMobile();

  // 白名单路由
  const whiteList = ["/", "/register", "/reset"];

  // 设备类型与路由匹配检查
  const isMobilePath = to.path.startsWith("/mobile");

  if (isMobileDevice && !isMobilePath && !whiteList.includes(to.path)) {
    // 移动设备访问PC路由时重定向到对应的移动端路由
    const mobilePathMap = {
      "/home": "/mobile",
      "/profile": "/mobile/profile",
      "/password": "/mobile/password",
      "/system/email": "/mobile/email",
    };
    const redirectPath = mobilePathMap[to.path] || "/mobile";
    next(redirectPath);
    return;
  }

  if (!isMobileDevice && isMobilePath) {
    // PC设备访问移动端路由时重定向到对应的PC端路由
    const pcPathMap = {
      "/mobile": "/home",
      "/mobile/profile": "/profile",
      "/mobile/password": "/password",
      "/mobile/email": "/system/email",
    };
    const redirectPath = pcPathMap[to.path] || "/home";
    next(redirectPath);
    return;
  }

  // 权限检查
  if (token) {
    if (whiteList.includes(to.path)) {
      next(isMobileDevice ? "/mobile" : "/home");
    } else {
      next();
    }
  } else {
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next("/");
    }
  }
});

export default router;
