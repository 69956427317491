const mobileRoutes = [
  {
    path: "/",
    name: "MobileLogin",
    component: () => import("../views/mobile/auth/Login.vue"),
    meta: { title: "登录" },
  },
  {
    path: "/register",
    name: "MobileRegister",
    component: () => import("../views/mobile/auth/Register.vue"),
    meta: { title: "注册" },
  },
  {
    path: "/reset",
    name: "MobileReset",
    component: () => import("../views/mobile/auth/ResetPassword.vue"),
    meta: { title: "重置密码" },
  },
  {
    path: "/mobile",
    name: "MobileIndex",
    component: () => import("../views/mobile/Index.vue"),
    meta: { title: "首页" },
  },
  {
    path: "/mobile/my",
    name: "MobileMy",
    component: () => import("../views/mobile/user/My.vue"),
    meta: { title: "我的" },
  },
  {
    path: "/mobile/profile",
    name: "MobileProfile",
    component: () => import("../views/mobile/user/Profile.vue"),
    meta: { title: "个人信息" },
  },
  {
    path: "/mobile/password",
    name: "MobilePassword",
    component: () => import("../views/mobile/user/Password.vue"),
    meta: { title: "修改密码" },
  },
  {
    path: "/mobile/email",
    name: "MobileEmail",
    component: () => import("../views/mobile/email/Email.vue"),
    meta: { title: "发送邮件" },
  },
];

export default mobileRoutes; 